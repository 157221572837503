.card-register {
  border-radius: 1px;
  background-color: #fff;
  margin: 20px 0 70px;
  min-height: 400px;
  padding: 30px;

  & label {
    margin-top: 15px;
  }
  & .card-title {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
  & .btn {
    margin-top: 30px;
  }
  & .forgot {
    text-align: center;
  }

  .input-group-text {
    border: 1px solid $white-color;
  }
}
