/*!

 =========================================================
 * Paper Kit React - v1.2.0 based on Paper Kit - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-kit-2
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

@import "paper-kit/variables";
@import "paper-kit/mixins";

// Plugins CSS
@import "paper-kit/plugins/plugin-bootstrap-switch";
@import "paper-kit/plugins/plugin-nouislider";
@import "paper-kit/plugins/plugin-datepicker";

// Core CSS
@import "paper-kit/buttons";
@import "paper-kit/inputs";
@import "paper-kit/checkbox-radio";
@import "paper-kit/progress-bars";
@import "paper-kit/badges";
@import "paper-kit/pagination";
@import "paper-kit/typography";
@import "paper-kit/misc";
@import "paper-kit/labels";

// components
@import "paper-kit/nucleo-icons";
@import "paper-kit/social-buttons";
@import "paper-kit/tabs-navs";
@import "paper-kit/navbars";
@import "paper-kit/dropdown";
@import "paper-kit/alerts";
@import "paper-kit/images";
@import "paper-kit/tooltips-and-popovers";
@import "paper-kit/modal";
@import "paper-kit/icons";
@import "paper-kit/carousel";
@import "paper-kit/footers";

// Cards
@import "paper-kit/cards";
@import "paper-kit/cards/card-plain";
@import "paper-kit/cards/card-register";
@import "paper-kit/cards/card-profile";

// example pages and sections
@import "paper-kit/examples";
@import "paper-kit/sections";

@import "paper-kit/responsive";

// React Differences
@import "react/react-differences.scss";
